import React, { useEffect, useState } from 'react';
import { Container, Grid, IconButton, Stack, Switch, Typography } from '@mui/material';
import { dispatch, useSelector } from '../../../../redux/store';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import useSettings from '../../../../hooks/useSettings';
import Page from '../../../../components/Page';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../../components/table/DataGridTable';
import { getStocks, restoreWastedStock } from '../../../../redux/slices/stockManagementRedux/stockRedux';
import { accessVerify, capitalize, safeJSONParse, utcMoment } from '../../../../utils/common';
import { useParams } from 'react-router-dom';
import { fCurrency } from '../../../../utils/formatNumber';
import RestoreIcon from '@mui/icons-material/Restore';
import { RemoveRedEye } from '@material-ui/icons';

const StockList = () => {
  const { showPOSOnly } = useParams();
  const { themeStretch } = useSettings();
  const [purchaseNotesList, setPurchaseNotesList] = useState([]);
  const [rowSelection, setRowSelection] = useState(false);
  const [includeEmptyStock, setIncludeEmptyStock] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);

  const { data: stocks, addData } = useSelector((state) => state?.stock);
  const { selectedLocation } = useSelector((state) => state?.location);

  useEffect(() => {
    dispatch(getStocks(selectedLocation?.id, -1, -1, -1, showPOSOnly, includeEmptyStock));
  }, [selectedLocation, showPOSOnly, includeEmptyStock, addData]);

  useEffect(() => {
    setPurchaseNotesList(
      stocks?.map((value) => {
        return {
          actionButton: safeJSONParse(value?.foodInfo)?.foodName,
          food: safeJSONParse(value?.foodInfo)?.foodName,
          foodType: capitalize(safeJSONParse(value?.foodInfo)?.foodType),
          location: safeJSONParse(value?.locationInfo)?.locationName,
          ...value,
          date: utcMoment(value?.date).format('DD MMMM YYYY'),
          unit: safeJSONParse(value?.unitInfo)?.unitName,
          supplier: safeJSONParse(value?.supplierInfo)?.supplierName,
          supplierCode: safeJSONParse(value?.supplierInfo)?.supplierCode,
          expireDate: utcMoment(value?.expireDate).format('DD MMMM YYYY'),
        };
      })
    );

    if (stocks?.length > 0) {
      setTotalPrice(stocks?.map((value) => value?.costPerUnit * value?.currentQty)?.reduce((a, c) => a + c) || 0);
    } else {
      setTotalPrice(0);
    }
  }, [stocks]);

  const columns = purchaseNotesList?.[0]
    ? Object.keys(purchaseNotesList?.[0])
        .map((value) => {
          if (value === 'foodInfo' || value === 'locationInfo' || value === 'supplierInfo' || value === 'unitInfo' || value === 'foodSupplierInfo' || value === 'parentId') {
            return null;
          } else if (value === 'actionButton') {
            return {
              accessorKey: value,
              header: capitalize(value),
              Cell: ({ cell, row }) => {
                return <><IconButton color='primary' onClick={() => {
                  const url = `/dashboard/stock/single-stock-item/${row.original.stockCode}`;
                  window.open(url, '_blank', 'noopener,noreferrer');
                }}>
                  <RemoveRedEye />
                </IconButton>

                  {row.original?.parentId && accessVerify("STOCK_QTY_RESTORE") && <IconButton color="primary" onClick={() => {
                    dispatch(restoreWastedStock(row?.original));
                  }}>
                    <RestoreIcon />
                  </IconButton>}
                </>;
              },
            };
          } else if (value === 'costPerUnit') {
            return {
              accessorKey: value,
              header: capitalize(value),
              Cell: ({ cell, row }) => {
                return fCurrency(row.original[value]);
              },
            };
          } else {
            return {
              accessorKey: value,
              header: capitalize(value),
            };
          }
        })
        .filter(Boolean)
    : [];

  return (
    <Page title='View Stock'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`View ${capitalize(showPOSOnly)} Stock`}
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Stock', href: PATH_DASHBOARD.stocks.root }, { name: `View ${capitalize(showPOSOnly)}` }]}
          action={[]}
        />
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Stack direction='row' alignItems='center'>
              <Typography variant='overline' sx={{ mr: 1.5 }}>
                Include Empty Stock
              </Typography>
              <Switch
                checked={includeEmptyStock}
                onChange={(e) => {
                  setIncludeEmptyStock(e.target.checked);
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            {showPOSOnly === 'full' && <h1 style={{ textAlign: 'right', color: 'red' }}>{fCurrency(totalPrice)}</h1>}
          </Grid>
        </Grid>
        {accessVerify('VIEW_STOCK_VIEW') && (
          <DataGridTable
            name={'View Stock'}
            data={purchaseNotesList}
            column={columns}
            isLoading={false}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            keyField={'stockId'}
            isRowClickable={false}
            onRowClick={(row) => {
              const url = `/dashboard/stock/single-stock-item/${row.original.stockCode}`;
              window.open(url, '_blank', 'noopener,noreferrer');
            }}
            enableRowActions={false}
            renderRowActionItems={(row) => []}
          />
        )}
      </Container>
    </Page>
  );
};

export default StockList;
