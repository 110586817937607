import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Chip, Container, Grid, Stack, SwipeableDrawer, TextField, Tooltip, Typography } from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../components/table/DataGridTable';
import { fCurrency } from '../../../utils/formatNumber';
import {
  deletePurchaseNotes,
  getPurchaseNotes,
  updatePurchaseNotes,
} from '../../../redux/slices/purchaseRedux/purchaseNotesRedux';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import { addStock } from '../../../redux/slices/stockManagementRedux/stockRedux';
import InvoicePDF from '../../../sections/@dashboard/invoice/details/InvoicePDF';
import { COMPANY_DATA_FOR_PDF } from '../../../config';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { accessVerify, capitalize, handleNumber, safeJSONParse } from '../../../utils/common';
import useSound from 'use-sound';
import successFx from '../../../assets/sounds/success.wav';
import errorFx from '../../../assets/sounds/error.wav';
import PermissionRequired from '../../errorPages/permissionRequired';
import { ORDER_STATUS } from '../../../utils/constants';

export default function FinanceConfirmation() {
  const theme = useTheme();
  const { themeStretch } = useSettings();
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [purchaseNotesList, setPurchaseNotesList] = useState([]);
  const [rowSelection, setRowSelection] = useState(false);
  const [selectedModelData, setSelectedModelData] = useState({});
  const [selectedItem, setSelectedItem] = useState({});

  const { data, addData } = useSelector((state) => state?.purchaseNotes);
  const { selectedLocation } = useSelector((state) => state?.location);

  let stockUpdated;
  ({ addData: stockUpdated } = useSelector((state) => state?.stock));

  useEffect(() => {
    dispatch(getPurchaseNotes());
  }, []);

  const [playErrorSound] = useSound(errorFx, {
    volume: 0.8,
    playbackRate: 1.2,
  });

  const [playSuccessSound] = useSound(successFx, {
    volume: 0.8,
    playbackRate: 1.2,
  });

  useEffect(() => {
    setPurchaseNotesList(
      data.map((value) => {
        return {
          ...value,
          itemList: safeJSONParse(value?.supplierorder_foodsupplierpricing).map((value) => {
            return {
              ...value,
              jsonData: { ...safeJSONParse(value?.jsonData), receivings: [] },
            };
          }),
        };
      })
    );
    setIsModelOpen(false);
  }, [data]);

  const manageModel = (data = {}) => {
    setSelectedModelData(data);
    if (isModelOpen) {
      setIsModelOpen(false);
    } else {
      setIsModelOpen(true);
    }
  };

  useEffect(() => {
    if (stockUpdated?.data, addData?.data) {
      dispatch(getPurchaseNotes());
    }
  }, [stockUpdated, addData]);

  const getItemsForInvoice = (rowData) => {
    if (rowData?.supplierorder_foodsupplierpricing) {
      const parsedRowData = safeJSONParse(rowData?.supplierorder_foodsupplierpricing);
      return parsedRowData?.map((value, index) => {
        const jsonData = safeJSONParse(value?.jsonData);
        const pricePerSupplierUnit = (jsonData?.price / jsonData?.purchaseUnitQty) * jsonData?.storeUnitQty;
        return {
          id: index,
          title: jsonData?.FoodName,
          description: `Final Price is checked on ${jsonData?.formatted_dateTime}.`,
          quantity: Number(value?.receivedQty) / (Number(value?.storeQty) / Number(value?.supplierQty)),
          storeUnitQty: jsonData?.storeUnitQty + ' ' + jsonData?.unitSymbol,
          price: pricePerSupplierUnit,
        };
      });
    }
  };

  const columns = !purchaseNotesList?.[0]
    ? []
    : Object.keys(purchaseNotesList?.[0])
      .map((value) => {
        if (
          value === 'itemList' ||
          value === 'supplierAddress' ||
          value === 'supplierCode' ||
          value === 'supplierCompanyPhone' ||
          value === 'paymentMethod' ||
          value === 'paymentDate' ||
          value === 'estimatedreceivingdate' ||
          value === 'orderedById' ||
          value === 'paymentmethod' ||
          value === 'paymentdate' ||
          value === 'supplierorder_foodsupplierpricing'
        ) {
          return null;
        } else if (value === 'supplierId') {
          return {
            accessorKey: 'supplierId',
            header: 'Invoice Id',
            Cell: ({ row }) => `INV-${row?.original?.id}`,
          };
        } else if (value === 'totalamount') {
          return {
            accessorKey: 'totalamount',
            header: 'Total Amount',
            Cell: ({ row }) => fCurrency(row?.original?.totalamount),
          };
        } else if (value === 'paymentforreceivings') {
          return {
            accessorKey: 'paymentforreceivings',
            header: 'Payment For Receivings',
            Cell: ({ row }) => fCurrency(row?.original?.paymentforreceivings),
          };
        } else if (value === 'paidamount') {
          return {
            accessorKey: 'paidamount',
            header: 'Paid Amount',
            Cell: ({ row }) => fCurrency(row?.original?.paidamount),
          };
        } else {
          return {
            accessorKey: value,
            header: capitalize(value),
          };
        }
      })
      .filter((value) => value);

  const validateBarcode = (barcode) => {
    return purchaseNotesList.some((value) => value.itemList.some((item) => item.jsonData.receivings.some((receiving) => receiving.barcode === barcode)));
  };
  const addReceiving = (barcode, qty, expiry) => {
    if (validateBarcode(barcode)) {
      playErrorSound();
      toast.error('Barcode already exists in the list');
    } else {
      playSuccessSound();
      const updatedPurchaseNotesList = purchaseNotesList.map((value) => {
        if (value?.id === selectedModelData?.id) {
          value.itemList = value.itemList.map((item) => {
            if (item?.id === selectedItem?.id) {
              item.jsonData.receivings.push({
                barcode,
                qty: Number(qty),
                expiry,
              });
            }
            return item;
          });
          return value;
        } else {
          return value;
        }
      });
      setPurchaseNotesList(updatedPurchaseNotesList);
    }
  };
  const getSumQtyOfEnteredItems = (value) => Number(value?.jsonData?.receivings?.reduce((acc, receiving) => acc + Number(receiving.qty), 0).toFixed(4));
  const handleChipClick = (item, chipIndex) => {
    const updatedPurchaseNotesList = purchaseNotesList.map((value) => {
      if (value?.id === selectedModelData?.id) {
        value.itemList = value.itemList.map((item) => {
          if (item?.id === selectedItem?.id) {
            item.jsonData.receivings = item.jsonData.receivings.filter((receiving, index) => index !== chipIndex);
          }
          return item;
        });
        return value;
      } else {
        return value;
      }
    });
    setPurchaseNotesList(updatedPurchaseNotesList);
  };

  const updateReceiving = () => {
    if (confirm('Are you sure you want to update stock with receiving?')) {
      dispatch(addStock({ location: selectedLocation, data: selectedModelData }));
    }
  };

  return (
    <Page title='Receiving'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading='Receiving'
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Receiving',
              href: PATH_DASHBOARD.stocks.root,
            },
            { name: 'Receiving' },
          ]}
          action={[]}
        />

        {accessVerify('GOODS_RECEIVING_VIEW') || accessVerify('PURCHASE_NOTE_VIEW') ? (
          <DataGridTable
            name={'Receivings'}
            data={purchaseNotesList}
            column={columns}
            isLoading={false}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            enableRowActions={true}
            renderRowActionItems={(row) => {
              return [
                <>
                  {row?.original?.status === 'RECEIVED' && (
                    <PDFDownloadLink
                      document={
                        <InvoicePDF
                          invoice={{
                            invoiceNumber: `INV-${row?.original?.id}`,
                            createDate: new Date(),
                            dueDate: new Date(new Date().setDate(new Date().getDate() + 30)),
                            status: row?.original?.status,
                            discount: 0,
                            taxes: 0,
                            paidAmount: handleNumber(row?.original?.paidAmount),
                            totalPrice: handleNumber(row?.original?.paymentforreceivings) - handleNumber(row?.original?.paidAmount),
                            subTotalPrice: handleNumber(row?.original?.paymentforreceivings),
                            topic: 'Received Item Details',
                            invoiceFrom: {
                              name: COMPANY_DATA_FOR_PDF?.name,
                              address: selectedLocation?.address,
                              phone: selectedLocation?.contactNumber,
                            },
                            invoiceTo: {
                              name: row?.original?.supplierName,
                              address: row?.original?.supplierAddress,
                              phone: row?.original?.supplierCompanyPhone,
                            },
                            items: getItemsForInvoice(row?.original),
                          }}
                        />
                      }
                      fileName={`INV-${row?.original?.id}`}
                      style={{ textDecoration: 'none' }}
                    >
                      {() => (
                        <Tooltip title='Download PDF'>
                          <Button variant='contained' startIcon={<Iconify icon='eva:download-fill' />} sx={{ padding: '3px 11px' }}>
                            {' '}
                            PDF
                          </Button>
                        </Tooltip>
                      )}
                    </PDFDownloadLink>
                  )}
                  {row?.original?.status === ORDER_STATUS.PENDING_FINANCE_CONFIRMATION.code && (
                    <Tooltip title='Set Confirmed by Finance'>
                      <Button
                        variant='contained'
                        startIcon={<Iconify icon='eva:check-fill' />}
                        color={'success'}
                        sx={{ padding: '3px 11px', marginLeft: '3px' }}
                        onClick={() => {
                          dispatch(
                            updatePurchaseNotes({
                              id: row?.original?.id,
                              status: 'RECEIVED',
                            })
                          );
                        }}
                      >
                        {' '}
                        Approve
                      </Button>
                    </Tooltip>
                  )}
                  {row?.original?.status === ORDER_STATUS.PENDING_FINANCE_CONFIRMATION.code && (
                    <Tooltip title='Delete Purchase Note'>
                      <Button
                        variant='contained'
                        startIcon={<Iconify icon='eva:check-fill' />}
                        color={'error'}
                        sx={{ padding: '3px 11px', marginLeft: '3px' }}
                        onClick={() => {
                          dispatch(
                            deletePurchaseNotes({
                              id: row?.original?.id
                            })
                          );
                        }}
                      >
                        Delete
                      </Button>
                    </Tooltip>
                  )}
                </>,
              ];
            }}
          />
        ) : (
          <PermissionRequired />
        )}
      </Container>
    </Page>
  );
}
